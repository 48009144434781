.vertical-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

table {
  border-collapse: separate;
  border-spacing: 30px;
  width: 85%;
}
td {
  padding: 30px;
  text-align: center;
}
td {
  position: relative;
}
