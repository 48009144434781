@use "../colors.scss";

.video-card-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6);

  &.ratio-lg {
    padding-bottom: 50%;
  }

  &.ratio-sm {
    padding-bottom: 56.25%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: colors.$transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  margin: 0;
  padding: 3%;
}
.edit-mode {
  opacity: 1;
  background: colors.$overlay;
}
.border-rounded {
  border-radius: 20px;
}
