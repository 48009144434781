$size-large-button: 60px;
$size-small-button: 40px;
$size-large-button-width: 90px;
$size-large-button-height: 70px;
$size-wide-button-width: 200px;
$size-wide-button-height: 40px;
$size-button-radius: 1rem;
@use "./colors.scss";

%button-common-styles {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: colors.$button;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 0;

  &:hover {
    background-color: colors.$button-hover;
  }

  &:disabled {
    background-color: colors.$button-disabled;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: colors.$button-svg;
  }

  &.active {
    border-color: colors.$button-active;
    border-style: solid;
    border-width: 3px;
    svg {
      fill: colors.$button-svg;
    }
  }

  &.active-playlist {
    background-color: colors.$button-hover;
    svg {
      fill: colors.$button-svg;
    }
  }
}

.large-button {
  @extend %button-common-styles;
  width: $size-large-button-width;
  height: $size-large-button-height;
  border-radius: $size-button-radius;
}

.large-round-button {
  @extend %button-common-styles;
  width: $size-large-button;
  height: $size-large-button;
  border-radius: 50%;
}

.small-button {
  @extend %button-common-styles;
  width: $size-small-button;
  height: $size-small-button;
}

.small-round-button {
  @extend %button-common-styles;
  width: $size-small-button;
  height: $size-small-button;
  border-radius: 50%;
}

.wide-button {
  @extend %button-common-styles;
  width: $size-wide-button-width;
  height: $size-wide-button-height;
  border-radius: $size-button-radius;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 3%;
}

.back-button {
  position: fixed;
  top: 3vh;
  left: 3vh;
  z-index: 1000;
}

.up-button {
  position: absolute;
  top: 3vh;
}
.down-button {
  position: absolute;
  bottom: 3vh;
}

.top-border-aligned {
  position: absolute;
  top: 2px;
  border-radius: 0 0 50% 50%;
}
.bottom-border-aligned {
  position: absolute;
  bottom: 4px; // to avoid a scroll bar during animation
  border-radius: 50% 50% 0 0;
}
