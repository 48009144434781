$light-background: #ffffff;
$dark-background: #2b2a2a;

$gazable-button: #ff0000;
$gazable-button-hover: #cc0000;
$button-disabled: #e4e2e2;
// $button: #e86558;
// $button-hover: #ab4238;
// $button: #674444;
// $button-hover: #663030;
$button: #534e4e;
$button-hover: rgb(148, 144, 144);
$button-active: rgb(34, 221, 49);
// $button-active: #44d102;

// blue
// $button: #03a9f4;
// $button-hover: #0288d1;

// green
// $button: #3ca444;
// $button-hover: #35923d;

// pink
// $button: #6a3a49;
// $button-hover: #5b3340;

//orange
// $button: #fc9c74;
// $button-hover: #ff8c5b;

$content: #ffffff;
$button-svg: $content;

$overlay: rgba(14, 14, 14, 0.7);
// $overlay: rgba(85, 84, 84, 0.7);
$transparent: rgba(85, 84, 84, 0);
