@use "./colors.scss";
@import "~/node_modules/bootstrap/scss/bootstrap";
@import "./buttons.scss";

.app {
  display: flex;
  justify-content: center;
  font-family: Arial, sans-serif;

  &.light {
    background-color: colors.$light-background;
  }
  &.dark {
    background-color: colors.$dark-background;
  }
}

.main-view {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.left-panel {
  flex-grow: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-panel {
  height: 100vh;
  padding: 3vh;
  display: flex;
  align-items: center;
}

.tool-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .large-round-button,
  .large-button {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
  }

  .wide-button {
    margin-top: 2.5vh;
    margin-bottom: 4vh;
  }

  .top-buttons,
  .bottom-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .top-buttons {
    margin-bottom: auto;
  }

  .bottom-buttons {
    margin-top: auto;
  }
}

[is="div-gazable"] {
  background-color: colors.$gazable-button;
  &:hover {
    background-color: colors.$gazable-button-hover;
  }
}

.full-space {
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
